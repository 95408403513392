import * as React from "react";
import * as FDN from "src/core";
import ReactSlider from "react-slider";
import prices, { IPricesDuration, IPricesMonths, IPricesMonthsPeriodsOfNotice } from "src/config/prices";
import { cloneDeep } from "lodash";

const currency = "EUR ";

interface ICalculation {
  months: number;
  hours: number;
  perHour: number;
  totalPerMonth: number;
  totalOverall: number;
}

const Calculator: React.FunctionComponent = () => {
  const viewAsAdmin: boolean = false;

  const [monthsStep, setMonthsStep] = React.useState(prices.monthsSteps.indexOf(prices.defaultMonths));
  const [hoursStep, setHoursStep] = React.useState(prices.hoursSteps.indexOf(prices.defaultHours));
  const [calculation, setCalculation] = React.useState<ICalculation>();

  const months = prices.monthsSteps[monthsStep];
  const hours = prices.hoursSteps[hoursStep];

  React.useEffect(() => {
    calculate();
  }, []);

  React.useEffect(() => {
    calculate();
  }, [months, hours]);

  const onChange = (type: "months" | "hours", value: number) => {
    if (type === "months") setMonthsStep(value);
    else if (type === "hours") setHoursStep(value);
  };

  const calculate = () => {
    const calculation: ICalculation = {
      months: prices.monthsSteps[monthsStep],
      hours: prices.hoursSteps[hoursStep],
      perHour: 0.0,
      totalPerMonth: 0.0,
      totalOverall: 0.0,
    };

    calculation.perHour =
      prices.months[calculation.months as keyof IPricesMonths][calculation.hours as keyof IPricesDuration];

    calculation.totalPerMonth = calculation.hours * calculation.perHour;

    calculation.totalOverall = calculation.months * calculation.totalPerMonth;

    setCalculation(cloneDeep(calculation));
  };

  if (!calculation) return <FDN.Loading box />;

  return (
    <div className="__calculator">
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12} mdo={6}>
            <div className="__calculator-box">
              <FDN.Box>
                <FDN.Grid full>
                  <FDN.Row margin="xy">
                    <FDN.Cell md={24}>
                      <label>Contract period (in months)</label>
                      <ReactSlider
                        value={monthsStep}
                        min={0}
                        max={prices.monthsSteps.length - 1}
                        step={1}
                        marks
                        className="horizontal-slider"
                        markClassName="slider-mark"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        onChange={(value) => onChange("months", value)}
                      />
                      <div className="__calculator-label">
                        {months} month
                        {months !== 1 ? `s` : ``}
                      </div>
                    </FDN.Cell>
                  </FDN.Row>
                  <FDN.Row margin="xy">
                    <FDN.Cell md={24}>
                      <label>Coding hours / month</label>
                      <ReactSlider
                        value={hoursStep}
                        min={0}
                        max={prices.hoursSteps.length - 1}
                        step={1}
                        marks
                        className="horizontal-slider"
                        markClassName="slider-mark"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        onChange={(value) => onChange("hours", value)}
                      />
                      <div className="__calculator-label">
                        {hours} hour
                        {hours !== 1 ? `s` : ``}
                      </div>
                    </FDN.Cell>
                  </FDN.Row>
                </FDN.Grid>
              </FDN.Box>
              <FDN.Box>
                <FDN.Grid full>
                  <FDN.Row margin="xy">
                    <FDN.Cell md={24}>
                      <div className="__calculator-summary-line type-perHour">
                        Per hour: {currency}
                        {FDN.formatNumber(calculation.perHour, 0, 2)}
                      </div>
                      <div className="__calculator-summary-line type-totalPerMonth">
                        Total per month: {currency}
                        {FDN.formatNumber(calculation.totalPerMonth, 0, 2)}
                      </div>
                      {viewAsAdmin ? (
                        <div className="__calculator-summary-line type-totalOverall">
                          Total overall: {currency}
                          {FDN.formatNumber(calculation.totalOverall, 0, 2)}
                        </div>
                      ) : null}
                      <div className="__calculator-summary-line type-periodofnotice">
                        Period of notice:{" "}
                        {prices.periodsOfNotice[calculation.months as keyof IPricesMonthsPeriodsOfNotice]} month
                        {prices.periodsOfNotice[calculation.months as keyof IPricesMonthsPeriodsOfNotice] !== 1
                          ? `s`
                          : ``}
                      </div>
                    </FDN.Cell>
                  </FDN.Row>
                </FDN.Grid>
              </FDN.Box>
            </div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default Calculator;
