export interface IPricesDuration {
  5: number;
  10: number;
  15: number;
  20: number;
}

export interface IPricesMonths {
  1: IPricesDuration;
  3: IPricesDuration;
  6: IPricesDuration;
  12: IPricesDuration;
  24: IPricesDuration;
}

export interface IPricesMonthsPeriodsOfNotice {
  1: number;
  3: number;
  6: number;
  12: number;
  24: number;
}

export interface IPrices {
  monthsSteps: number[];
  hoursSteps: number[];
  defaultMonths: number;
  defaultHours: number;
  months: IPricesMonths;
  periodsOfNotice: IPricesMonthsPeriodsOfNotice;
}

const prices: IPrices = {
  monthsSteps: [1, 3, 6, 12], // no 24 months available right now
  hoursSteps: [5, 10, 15, 20],
  defaultMonths: 6,
  defaultHours: 10,
  months: {
    1: {
      5: 120,
      10: 115,
      15: 110,
      20: 105,
    },
    3: {
      5: 110,
      10: 105,
      15: 100,
      20: 95,
    },
    6: {
      5: 100,
      10: 95,
      15: 90,
      20: 85,
    },
    12: {
      5: 90,
      10: 85,
      15: 80,
      20: 75,
    },
    24: {
      5: 100,
      10: 90,
      15: 80,
      20: 70,
    },
  },
  periodsOfNotice: {
    1: 1,
    3: 1,
    6: 1,
    12: 3,
    24: 3,
  },
};

export default prices;
