import "src/stylesheets/app.scss";
import Calculator from "./components/calculator";

function App() {
  return (
    <div className="App">
      <Calculator />
      {/* <Test /> */}
    </div>
  );
}

export default App;
